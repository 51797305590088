import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AppleLogin from 'react-apple-login';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import logo from '../../assets/logo.png';
import {
  Background,
  ModalContent,
  ModalWrapper,
  Title,
  RegisterForm,
  AuxTextFooter,
  HeaderBar,
  PasswordBox,
  PasswordRecoveryButton,
  LoginForm,
  Logo,
} from './styles';
import api, { LOGIN_API_URL } from '../../services/api';
import Loader from '../Loader';
import { IProps } from './contracts';

import { errorMessages, errorMessageTypes } from '../../constants/messages';
import { signInRequest, updateUserProfileSelfRequest, googleLogin } from '../../store/ducks/auth/actions';
import { SignUpConfirmModal } from '../SignUpConfirmModal';
import { EmailAlreadyExistsModal } from '../EmailAlreadyExistsModal';
import { IStore } from '../../contracts';
import theme from '../../theme';
import SocialLoginButton from '../SocialLoginButton';

export const SignUpSelfModal = ({
  showModal, setShowModal, setPaymentProfile, productName,
}: IProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  // fields
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // field errors
  const [nameError, setNameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const errorMessage = useSelector((state: IStore) => state.auth.errorMessage);

  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalEmailExists, setModalEmailExists] = useState(false);

  const userId = useSelector((state: IStore) => state.auth.profile?.id);

  const googleLoginHandle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      const data = {
        name: userInfo.name ?? `${userInfo.givenName} ${userInfo.familyName}`,
        email: userInfo.email,
        token: tokenResponse.access_token,
        photo: userInfo.picture ?? '',
        provider: 'google',
        origin: 'WEB',
      };

      dispatch(googleLogin(data));
    },
  });

  function validateData() {
    if (!name) setNameError('*Campo obrigatório');
    if (!phone) setPhoneError('*Campo obrigatório');
    if (!email) setEmailError('*Campo obrigatório');
    if (!password) setPasswordError('*Campo obrigatório');

    if (
      !name
      || !phone
      || !email
      || !password
    ) {
      return false;
    }

    return true;
  }

  async function handleSubmit(event: { preventDefault: () => void; }, sendDataConfirmed = false) {
    event.preventDefault();

    const successValidate = validateData();

    if (!successValidate) return;

    if (!sendDataConfirmed) {
      setModalConfirm(true);
      return;
    }

    setLoading(true);

    try {
      const data = {
        name,
        phone,
        email,
        password,
        product_name: productName,
      };

      await api.post('/storeFromCheckout', data);

      setLoading(false);

      setPaymentProfile(null);

      setShowModal(false);

      dispatch(signInRequest(email, password));
    } catch (error: any) {
      setLoading(false);

      if (error.response.data?.message === errorMessageTypes.USER_ALREADY_EXISTS) {
        toast.error(errorMessages.USER_ALREADY_EXISTS);
        setModalEmailExists(true);
      }
    }
  }
  function handleSubmitLogin(event: { preventDefault: () => void; }) {
    event.preventDefault();

    if (!email || !password) {
      // dispatch(setErrorMessage('Preencha todos os campos'));
      return;
    }

    dispatch(signInRequest(email, password));
  }

  function handleConfirmSubmit(event: { preventDefault: () => void; }) {
    handleSubmit(event, true);
  }

  function setShowModalCallback(value: boolean) {
    setModalConfirm(value);
    setShowModal(value);
  }

  function updateUserProfileCallback() {
    const profilePayload = {
      name,
      phone,
      cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
    };

    dispatch(updateUserProfileSelfRequest(userId, profilePayload));
  }

  return (
    <>
      {loading && <Loader />}

      {showModal ? (
        <>
          {
            !modalEmailExists
            && (
              <SignUpConfirmModal
                showModal={modalConfirm}
                setShowModal={() => setModalConfirm(false)}
                finishPayment={(e) => handleConfirmSubmit(e)}
                email={email}
                cpf={cpf}
                phone={phone}
              />
            )
          }

          <EmailAlreadyExistsModal
            showModal={modalEmailExists}
            setShowModal={() => setModalEmailExists(false)}
            emailExists={email}
            setShowModalCallback={(value) => setShowModalCallback(value)}
            updateUserProfileCallback={() => updateUserProfileCallback()}
          />

          {
            !modalConfirm
            && !modalEmailExists
            && (
              <Background ref={modalRef}>
                <animated.div style={animation}>
                  <ModalWrapper showModal={showModal}>
                    <ModalContent>
                      <Logo src={logo} alt="logo" />
                      {!showLogin && (
                        <>
                          <HeaderBar>
                            <Title>
                              <strong>Cadastro</strong>
                            </Title>
                          </HeaderBar>

                          <RegisterForm onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor="name">Nome completo</label>
                            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                            {nameError && <small style={{ color: 'red', marginBottom: '5px' }}>{nameError}</small>}

                            <label htmlFor="phone">Telefone celular</label>
                            <PhoneInput
                              country="br"
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                              countryCodeEditable={false}
                              inputProps={{
                                name: 'phone',
                                id: 'phone',
                                required: true,
                              }}
                              containerStyle={{
                                width: '100%',
                                height: '47px',
                                border: 'none',
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                              buttonStyle={{
                                border: 'none',
                                backgroundColor: theme.colors.cardB3,
                              }}
                              inputStyle={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                backgroundColor: theme.colors.cardB3,
                              }}
                            />
                            {phoneError && <small style={{ color: 'red', marginBottom: '5px' }}>{phoneError}</small>}

                            <label htmlFor="email">E-mail para login</label>
                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {emailError && <small style={{ color: 'red', marginBottom: '5px' }}>{emailError}</small>}

                            <label htmlFor="password">Escolha uma senha</label>
                            <PasswordBox>
                              <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                              <div onClick={() => setShowPassword(!showPassword)}>
                                {showPassword
                                  ? <FaEyeSlash size={25} color="#fff" />
                                  : <FaEye size={25} color="#fff" />}
                              </div>
                            </PasswordBox>
                            {passwordError && <small style={{ color: 'red', marginBottom: '5px' }}>{passwordError}</small>}

                            <AuxTextFooter>
                              <p>
                                Ao se cadastrar você concorda com os
                                {' '}

                                <strong>
                                  <a href={process.env.REACT_APP_CP_TERMS_URL} target="_blank" rel="noopener noreferrer">termos de uso</a>
                                </strong>

                                {' '}
                                e a
                                {' '}

                                <strong><a href={process.env.REACT_APP_CP_POLICY_URL} target="_blank" rel="noopener noreferrer">política de privacidade</a></strong>
                                .
                              </p>
                            </AuxTextFooter>

                            <button type="submit">
                              <strong>Continuar</strong>
                            </button>
                          </RegisterForm>
                          <AuxTextFooter>
                            <p>
                              Já tem cadastro?
                              {' '}
                              <strong style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowLogin(true)}>
                                Faça seu login.
                              </strong>
                            </p>
                          </AuxTextFooter>
                        </>
                      )}

                      {showLogin && (
                        <>
                          <HeaderBar>
                            <Title>
                              <strong>Login</strong>
                            </Title>
                          </HeaderBar>
                          <LoginForm onSubmit={(e) => handleSubmitLogin(e)}>

                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" placeholder="Digite seu email" value={email} onChange={(e) => setEmail(e.target.value)} />

                            <label htmlFor="password">Senha</label>
                            <input type="password" id="password" placeholder="Digite sua senha" value={password} onChange={(e) => setPassword(e.target.value)} />

                            <br />
                            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                            <br />

                            <button type="submit">
                              <strong>Acessar</strong>
                            </button>

                            <SocialLoginButton
                              title="Fazer login com Google"
                              loading={false}
                              type="google"
                              onClick={() => googleLoginHandle()}
                              disabled={false}
                            />

                            <AppleLogin
                              clientId="com.corridaperfeita.web"
                              redirectURI={`${LOGIN_API_URL}/apple-web-pay`}
                              callback={() => { }}
                              responseType="code id_token"
                              responseMode="form_post"
                              scope="name email"
                              render={(renderProps) => (
                                <SocialLoginButton
                                  title="Fazer login com Apple"
                                  loading={false}
                                  type="apple"
                                  onClick={renderProps.onClick}
                                  disabled={false}
                                />
                              )}
                            />

                            <PasswordRecoveryButton href={`${process.env.REACT_APP_APP2_BASE_URL}/solicitar-recuperar-senha`} target="_blank" rel="noopener noreferrer">
                              Recuperar senha
                            </PasswordRecoveryButton>
                          </LoginForm>

                          <AuxTextFooter>
                            <p>
                              <strong style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowLogin(false)}>
                                Voltar
                              </strong>
                            </p>
                          </AuxTextFooter>
                        </>
                      )}
                    </ModalContent>
                  </ModalWrapper>
                </animated.div>
              </Background>
            )
          }
        </>
      ) : null}
    </>
  );
};
