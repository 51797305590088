import React, {
  useEffect, useState, ChangeEvent, useCallback, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import { debounce } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import Payment from 'payment';
import american_express_logo from '../../assets/american_express.png';
import dinners_club_logo from '../../assets/dinners_club.png';
import elo_logo from '../../assets/elo.png';
import hipercard_logo from '../../assets/hipercard.png';
import jcb_logo from '../../assets/jcb-logo.png';
import mastercard_logo from '../../assets/mastercard.png';
import visa_logo from '../../assets/visa.png';
import Header from '../../components/Header';
import theme from '../../theme';
import {
  Container,
  Steps,
  OptionTitle,
  Discount,
  ProductName,
  ValueDetails,
  DiscountButton,
  OptionContainer,
  LoginButton,
  SignUpButton,
  RenewDate,
  RenewValue,
  AuthButtons,
  PayNow,
  PaymentMethodsContainer,
  PaymentOption,
  PaymentOptionDetails,
  GenericOptionDetails,
  ContainerFlags,
  InputText,
  CardSecurityCodeContainer,
  CardSecurityCode,
  CardValidDate,
  InstallmentsSelect,
  InstallmentsSelectOption,
  AuxTextFooter,
  CouponFill,
  TotalOldValue,
  SSLStamp,
  DiscountButtonRemove,
  AlreadyCreditCardSavedTitle,
  AlreadyCreditCardSavedSubtitle,
  AlreadyCreditCardSavedNumberContainer,
  AlreadyCreditCardSavedNumber,
  AlreadyCreditCardSavedSwitchButton,
  SaveAddressButton,
  AddressButtonContainer,
  HasSubscriptionValidMessageContainer,
  HasSubscriptionValidMessageMailLink,
  HasSubscriptionValidMessage, ErrorMsg, InputMasked, CardNumber, CardFlag,
  PartnerDetails,
  DiscountTitle,
  DiscountUniclass,
} from './styles';
import api, { api2 } from '../../services/api';
import { SignInModal } from '../../components/SignInModal';
import { SignUpModal } from '../../components/SignUpModal';
import Loader from '../../components/Loader';
import ProcessingLoader from '../../components/ProcessingLoader';
import { convertRecurrenceToPaymentNextBilling, convertRecurrenceToPaymentPeriodInstallments, formatCouponMessageError } from '../../utils';
import {
  ICoupon, IProduct, IProps, IPaymentMethod, IError, IPaymentProfile, IPlanItem,
} from './contracts';
import { errorMessages, errorMessageTypes } from '../../constants/messages';
import { couponTypes, paymentMethodCodes } from '../../constants/payment';
import sslStampImage from '../../assets/compra-dados-seguros.png';
import { IStore } from '../../contracts';
import { setPaymentToken, signInSuccess } from '../../store/ducks/auth/actions';
import { SavePhoneModal } from '../../components/SavePhoneModal';
import { SaveCpfModal } from '../../components/SaveCpfModal';
import { SaveAddressModal } from '../../components/SaveAddressModal';
import { SignUpSelfModal } from '../../components/SignUpSelfModal';
import { SaveAddressCPFModal } from '../../components/SaveCPFAdressModal';

const CardNumberInput = (props: any) => (
  <InputMasked
    mask="9999 9999 9999 9999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    placeholder="0000 0000 0000 0000"
  />
);

const CardValidDateInput = (props: any) => (
  <InputMasked
    mask="99/9999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    placeholder="00/0000"
  />
);

const CardCVVInput = (props: any) => (
  <InputText
    maxLength={4}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

function PaymentDetails(props: IProps) {
  const myProps: any = props;
  const self = myProps.location.pathname.includes('self');
  const valentine = myProps.location.pathname.includes('s2');
  const history = useHistory();
  const token = useSelector((state: IStore) => state.auth.token);
  const profile = useSelector((state: IStore) => state.auth.profile);
  const [productData, setProductData] = useState<IProduct | null>(null);
  const [paymentOption, setPaymentOption] = useState(0);
  const [showCoupon, setShowCoupon] = useState(true);
  const [isItauUniclass, setIsItauUniclass] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignSelfModal, setShowSignSelfModal] = useState(self);
  const [showSavePhoneModal, setShowSavePhoneModal] = useState(false);
  const [showSaveCpfModal, setShowSaveCpfModal] = useState(false);
  const [showSaveAddressModal, setShowSaveAddressModal] = useState(false);
  const [showSaveCPFAddressModal, setShowSaveCPFAddressModal] = useState(false);
  const [couponApplied, setCouponApplied] = useState<string | null>(null);
  const [restrictedEmails, setRestrictedEmails] = useState<string | null>(null);
  const [couponField, setCouponField] = useState('');
  const [couponText, setCouponText] = useState('');
  const [couponAppliedValue, setCouponAppliedValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [productValue, setProductValue] = useState(0);
  const [productNextBillingValue, setProductNextBillingValue] = useState(0);
  const [processingLoaderText, setProcessingLoaderText] = useState('Preparando checkout...');
  const [processingLoading, setProcessingLoading] = useState(false);
  const [creditCardPaymentMethod, setCreditCardPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [bankSlipPaymentMethod, setBankSlipPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [pixPaymentMethod, setPixPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [cardFlag, setCardFlag] = useState('');
  const [gratuity, setGratuity] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardValidDate, setCardValidDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [installments, setInstallments] = useState('12');
  const [maxInstallments, setMaxInstallments] = useState('12');
  const [possibleInstallments, setPossibleInstallments] = useState<string[]>([]);
  const paymentToken = useSelector((state: IStore) => state.auth.paymentToken);
  const dispatch = useDispatch();
  const [paymentProfile, setPaymentProfile] = useState<IPaymentProfile | null>(null);
  const [userCountry, setUserCountry] = useState('');
  const [hasAddress, setHasAddress] = useState(false);
  const [onlySaveAddress, setOnlySaveAddress] = useState(false);
  const [updatePhone, setUpdatePhone] = useState(false);
  const [accessionValue, setAccessionValue] = useState(0);
  const [mainValue, setMainValue] = useState(0);
  const [showHasSubscriptionValidMessage, setShowHasSubscriptionValidMessage] = useState(false);
  const [proceedPay, setProceedPay] = useState(true);
  const [cid, setCid] = useState('');
  // fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const openSignInModal = () => {
    setShowSignInModal((prev) => !prev);
  };

  const openSignUpModal = () => {
    setShowSignUpModal((prev) => !prev);
  };

  const openSavePhoneModal = (update = false, proceed = true) => {
    if (update) {
      setUpdatePhone(true);
    }

    if (!proceed) {
      setProceedPay(false);
    }

    setShowSavePhoneModal((prev) => !prev);
  };

  const openSaveCpfModal = () => {
    setShowSaveCpfModal((prev) => !prev);
  };

  const openSaveAddressModal = () => {
    setShowSaveAddressModal((prev) => !prev);
  };

  const openSaveAddressCPFModal = () => {
    setShowSaveCPFAddressModal((prev) => !prev);
  };

  const openOnlySaveAddressModal = () => {
    setOnlySaveAddress(true);

    setShowSaveAddressModal((prev) => !prev);
  };

  const listBins = [
    '5536 36',
    '5234 32',
    '5234 31',
    '5244 74',
    '5593 37',
    '5156 40',
    '4901 72',
    '5491 67',
    '4771 76',
    '5148 68',
    '5520 72',
    '4145 04',
    '4192 38',
    '4192 37',
    '4110 49',
    '5276 16',
    '5445 07',
    '5439 60',
    '4594 54',
    '4594 57',
    '4594 58',
    '4032 47',
    '5448 59',
    '4594 55',
    '5441 69',
    '4590 77',
    '4590 78',
    '4590 80',
    '5429 76',
    '5445 70',
    '5415 55',
    '4590 20',
    '4590 23',
    '5429 74',
    '5445 40',
    '4594 50',
    '4004 39',
    '4893 91',
    '5443 00',
    '5247 03',
    '5460 56',
    '5536 37',
    '4831 51',
    '5209 77',
    '5257 18',
    '5149 45',
    '5536 65',
    '4145 06',
    '4220 05',
    '4831 50',
    '5316 81',
    '4950 00',
    '4950 18',
    '4950 06',
    '4950 08',
    '4950 14',
    '4950 16',
    '4950 21',
    '4950 22',
    '4950 37',
    '4950 38',
    '5498 63',
    '5284 12',
    '5284 13',
    '5343 59',
    '5407 52',
    '5412 45',
    '5412 57',
    '5412 58',
    '5446 77',
    '5446 78',
    '5446 94',
    '5482 69',
    '3716',
    '3786',
    '3726',
    '3791 914',
    '4593 26',
    '3791 91',
    '3751 69',
    '5466 98',
    '5418 63',
    '5402 85',
    '4841 44',
    '4338 28',
    '4066 99',
    '4841 45',
    '4581 89',
    '4841 46',
    '4263 50',
    '5336 62',
    '5451 12',
    '5181 41',
    '4551 48',
    '4633 08',
    '5327 6300',
    '5196 97',
    '4191 89',
    '4495 95',
    '5213 12',
    '5409 37',
    '5436 96',
    '5451 96',
    '5522 52',
    '5546 10',
    '5549 04',
    '5598 00',
    '5598 01',
    '4177 60',
    '5475 51',
    '5330 13',
    '5583 47',
    '5201 86',
    '5183 61',
    '5491 66',
    '5523 03',
    '4506 02',
    '4704 32',
    '4539 22',
    '4066 78',
    '4575 66',
    '4303 66',
    '4859 91',
    '5525 58',
    '4824 76',
    '4901 44',
    '4705 98',
    '5274 95',
    '5457 19',
    '4830 85',
    '5292 85',
    '5293 23',
    '4771 29',
    '4771 28',
    '5248 20',
    '5232 84',
    '5268 63',
    '6370 95',
    '4213 09',
    '5224 46',
    '5254 96',
    '4218 48',
    '4440 54',
    '4393 54',
    '5489 84',
    '5489 85',
    '5157 43',
    '4160 94',
    '5123 74',
    '5181 38',
    '4657 69',
    '4824 79',
    '4824 77',
    '4824 78',
    '5256 62',
    '5256 63',
    '5256 64',
    '5123 63',
    '5459 57',
    '5483 05',
    '5488 39',
    '6375 68',
    '4593 14',
    '4593 13',
    '4593 15',
    '4593 16',
    '5487 23',
    '5487 24',
    '5484 74',
    '5417 59',
    '5458 23',
    '4894 00',
    '5459 59',
    // '5200 4897 7844 3582',
  ];

  const isBinItauUniclass = (number: string) => listBins.some((bin) => number.includes(bin));

  const getIssuer = async (number: any) => {
    if (isBinItauUniclass(number) && number.length === 19) {
      let applied = false;
      if (productData?.url_identifier.includes('start')) {
        applied = await applyCoupon(false, 'UNICLASS20');
        setShowDiscount(true);
      } else if (productData?.url_identifier.includes('clube-essencial-semestral-cp-itau')) {
        applied = await applyCoupon(false, 'UNICLASS0');
        setShowDiscount(false);
      } else {
        applied = await applyCoupon(false, 'UNICLASS15');
        setShowDiscount(true);
      }
      setIsItauUniclass(applied);
      setShowCoupon(false);
      if (showDiscount) {
        toast.success('Desconto cliente Itaú Uniclass aplicado com sucesso');
      }
    } else {
      setIsItauUniclass(false);
    }
    setCardNumber(number);
    let issuer = Payment.fns.cardType(number);
    issuer = issuer === 'amex' ? 'american_express' : issuer;
    if (creditCardPaymentMethod && creditCardPaymentMethod.payment_companies) {
      const cardFlag = creditCardPaymentMethod.payment_companies.find((cc) => cc.code === issuer);
      const cc = cardFlag ? cardFlag.code : '';
      setCardFlag(cc);
    }
  };

  const loadUser = useCallback(() => {
    const params = new URLSearchParams(props?.location?.search);

    const queryToken = params.get('token');
    const cid = params.get('cid');
    setCid(cid || '');

    let { tokenMobile } = props?.match?.params;

    if (!tokenMobile && queryToken) {
      tokenMobile = queryToken;
    }

    let profileMobile: any = {};

    if (tokenMobile) {
      profileMobile = JSON.parse(atob(tokenMobile.split('.')[1]));
      api.defaults.headers.Authorization = `Bearer ${tokenMobile}`;
    }

    if (token || tokenMobile) {
      const id = profile ? profile.id : profileMobile.uid;
      api.get(`/users/${id}`)
        .then((response) => {
          const { data } = response;
          if (tokenMobile) {
            dispatch(signInSuccess(tokenMobile, {
              id: data.id, name: data.name, email: data.email, phone: data.phone,
            }));
          }
          if (data.address) setHasAddress(true);

          if (data.address?.country?.toLowerCase() === 'brasil' || data.address?.country?.toLowerCase() === 'brazil' || data.address?.country?.toLowerCase() === 'br') {
            setUserCountry('BR');
          }
        });
    }
  }, [token]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    api.get(`/payments/products/vindi?url_identifier=${props.match.params.product}`).then((response) => {
      const { data } = response;
      if (data && data.length > 0) {
        setProductData(data[0]);
        setProductValue(data[0].price);
        if (props.match.params.coupon) {
          setCouponText(props.match.params.coupon);
        }

        if (data[0].plan_items?.length > 0) {
          const plan_items_only_in_first_cycle = data[0].plan_items.filter((item: IPlanItem) => item.cycles && item.cycles === 1);

          let next_value = data[0].price;

          if (plan_items_only_in_first_cycle && plan_items_only_in_first_cycle.length > 0) {
            for (let index = 0; index < plan_items_only_in_first_cycle.length; index += 1) {
              const plan_item = plan_items_only_in_first_cycle[index];
              next_value -= plan_item.pricing_schema?.price;
            }
            const { name } = data[0];
            const showAccessionValue = !name.includes('FILA');
            setProductNextBillingValue(next_value);
            if (showAccessionValue) {
              setAccessionValue(data[0].price - next_value);
            }

            const mainValue = data[0].price - (data[0].price - next_value);
            setMainValue(mainValue);
          } else {
            setProductNextBillingValue(next_value);
            setMainValue(data[0].price);
          }
        }

        if (data[0].possible_installments && data[0].possible_installments.length > 0) {
          const possible_installments = data[0].possible_installments.split(',').sort((a: string, b: string) => {
            if (parseInt(a, 10) > parseInt(b, 10)) return -1;

            return 1;
          });

          setMaxInstallments(possible_installments[0]);
          setInstallments(possible_installments[0]);
          setPossibleInstallments(possible_installments);
        }
      } else {
        history.push('/error');
      }
    }).catch(() => {
      history.push('/error');
    });
  }, [history, props.match.params.product]);

  useEffect(() => {
    if (couponText && props.match.params.coupon) {
      applyCoupon(true);
    }
  }, [couponText]);

  useEffect(() => {
    api.get('/payments/payment_methods/vindi')
      .then((response) => {
        if (response.data?.payment_methods?.length > 0) {
          response.data.payment_methods.map((paymentMethod: IPaymentMethod) => {
            if (paymentMethod.code === paymentMethodCodes.CREDIT_CARD) {
              setCreditCardPaymentMethod({
                code: paymentMethod.code,
                public_name: paymentMethod.public_name,
                status: paymentMethod.status,
                payment_companies: paymentMethod.payment_companies,
              });
            } else if (paymentMethod.code === paymentMethodCodes.BANK_SLIP) {
              setBankSlipPaymentMethod({
                code: paymentMethod.code,
                public_name: paymentMethod.public_name,
                status: paymentMethod.status,
              });
            } else if (paymentMethod.code === paymentMethodCodes.PIX && !valentine) {
              setPixPaymentMethod({
                code: paymentMethod.code,
                public_name: paymentMethod.public_name,
                status: paymentMethod.status,
              });
            }

            return paymentMethod;
          });
        }

        setLoading(false);
      })
      .catch(() => {
        toast.error(errorMessages.GET_PAYMENT_METHODS_FAILURE);
      });
  }, []);

  useEffect(() => {
    if (token) {
      api.get('/payments/payment_profiles/vindi')
        .then((response) => {
          if (response.data.length > 0) {
            setPaymentProfile(response.data[0]);
          }
        });
    }
  }, [token]);

  async function getPaymentToken() {
    try {
      const response = await api.get('/payment_tokens');

      dispatch(setPaymentToken(response.data.token));
    } catch (error) {
      history.push('/error');
    }
  }

  useEffect(() => {
    if (paymentToken) {
      api.get(`/payment_tokens/${paymentToken}`).then((response) => {
        if (response.data.is_valid === 0) {
          getPaymentToken();
        }
      }).catch(() => {
        history.push('/error');
      });
    } else {
      getPaymentToken();
    }
  }, []);

  useEffect(() => {
    if (token && productData) {
      if (!profile.phone) {
        setTimeout(() => openSavePhoneModal(false, false), 5000);
        return;
      }

      const payload = {
        user_id: profile.id,
        plan_name: productData.name,
        origin: 'web-checkout-view',
      };

      api.post('/checkout_view', payload);

      const beehiivPayload = {
        userId: profile.id,
        customFields: [{ name: 'last_product_checkout_cp', value: productData.name }],
        tags: ['web-checkout-register'],
      };

      api2.post('/beehiiv/update-tag', beehiivPayload);
      api2.post('/beehiiv/update-cf', beehiivPayload);
    }
  }, [token, productData, profile?.phone]);

  async function applyCoupon(internal: boolean = true, coupon: string = '') {
    const couponToApply = coupon || couponText;
    if (couponToApply && productData) {
      setLoading(true);
      let show = false;
      try {
        let url = `/coupons/${couponToApply}/validateWithoutAuth?plan_id=${productData?.external_id}`;

        if (token) {
          url = `/coupons/${couponToApply}/validate?plan_id=${productData?.external_id}`;
        }

        const { data } = await api.get<ICoupon>(url);

        if (data.value < couponAppliedValue) {
          setLoading(false);
          return false;
        }
        show = !!data.value && data.value > 0;
        if (internal) {
          setShowCoupon(show);
        }
        if (data.restricted_emails) {
          setRestrictedEmails(data.restricted_emails);
        }
        if (data.type === couponTypes.MONETARY) {
          setProductValue((productData?.price || 0) - data.value);
          setDiscount(data.value);

          if (data.quantity_payments_applicable > 1) {
            setProductNextBillingValue(productNextBillingValue - data.value);
          } else if (!data.quantity_payments_applicable) {
            setProductNextBillingValue(productNextBillingValue - data.value);
          }
        } else if (data.type === couponTypes.PERCENTAGE) {
          if (data.value === 100.00) {
            setProductValue(0);
          } else {
            setProductValue((productData?.price || 0) - (((productData?.price || 0) / 100) * data.value));
          }

          if ((productData?.price || 0) - (((productData?.price || 0) / 100) * data.value) <= 0) {
            setGratuity(true);
          }
          setDiscount(((productData?.price || 0) / 100) * data.value);

          if (data.quantity_payments_applicable > 1) {
            setProductNextBillingValue(productNextBillingValue - ((productNextBillingValue / 100) * data.value));
          } else if (!data.quantity_payments_applicable) {
            setProductNextBillingValue(productNextBillingValue - ((productNextBillingValue / 100) * data.value));
          }
        }

        setHasCoupon(false);
        setCouponApplied(couponToApply);
        setCouponAppliedValue(data.value);
        setLoading(false);

        if (internal && show) toast.success('Cupom aplicado com sucesso');
        return true;
      } catch (error: any) {
        setLoading(false);
        if (show) toast.error(formatCouponMessageError(error.response.data.message));
        return false;
      }
    }
    return false;
  }

  const removeCoupon = () => {
    setProductValue(productData?.price || 0);
    setProductNextBillingValue(mainValue);
    setDiscount(0);
    setCouponApplied(null);
    setCouponField('');
  };

  function validateCardFields() {
    if (
      !cardHolderName
      || !cardValidDate
      || !cardNumber
      || !cardCVV
      || !cardFlag
    ) {
      return false;
    }

    return true;
  }

  const delayedQuery = useRef(
    debounce((value: string) => {
      setCouponText(value);
    }, 1000),
  ).current;

  function handleCoupon(value: string) {
    setCouponField(value);
    delayedQuery(value);
  }

  const finishPayment = async () => {
    setShowHasSubscriptionValidMessage(false);

    if (couponApplied && restrictedEmails) {
      const emails = restrictedEmails.split(',');
      const hasAccessCoupon = emails.some((email) => email === profile.email);
      if (!hasAccessCoupon) {
        toast.warning('Seu e-mail não está autorizado para uso deste cupom');
        return;
      }
    }
    // Para quando gratuito
    setPaymentOption(!paymentOption ? 3 : paymentOption);

    if (valentine && (!name || !email || !phone)) {
      toast.warning('Preencha os dados do seu parceiro(a)!');
      return;
    }
    if (paymentOption === 1) {
      try {
        if (productValue && !paymentProfile && !validateCardFields()) {
          toast.warning('Preencha os dados do cartão de crédito');
          return;
        }

        let payload: any = null;

        if (paymentProfile) {
          payload = {
            payment_token: paymentToken,
            credit_card: {
              already_saved: 'true',
            },
            product: {
              id: productData?.external_id,
              installments: parseInt(installments, 10),
              couponCode: '',
            },
          };
        } else if (!productValue) {
          payload = {
            payment_token: paymentToken,
            credit_card: {
              already_saved: 'true',
            },
            product: {
              id: productData?.external_id,
              installments: parseInt(installments, 10),
              couponCode: '',
            },
          };
        } else {
          payload = {
            payment_token: paymentToken,
            credit_card: {
              holder_name: cardHolderName,
              card_expiration: cardValidDate,
              card_number: cardNumber,
              card_cvv: cardCVV,
              payment_method_code: creditCardPaymentMethod?.code,
              payment_company_code: cardFlag,
            },
            product: {
              id: productData?.external_id,
              installments: parseInt(installments, 10),
              couponCode: '',
            },
          };
        }

        Object.entries(payload.credit_card).forEach(([key, value]: [string, any]) => {
          payload.credit_card[key] = CryptoJS.AES.encrypt(value, paymentToken).toString();
        });

        if (couponApplied) payload.product.couponCode = couponApplied;

        setProcessingLoaderText('Processando pagamento...');
        setProcessingLoading(true);

        payload.self = self;
        if (valentine) {
          payload.nameValentine = name;
          payload.emailValentine = email;
          payload.phoneValentine = phone;
        }
        const { data } = await api.post(`payments/vindi/with_credit_card/?cid=${cid}`, payload);

        if (paymentProfile) {
          setPaymentProfile(null);
        } else {
          setCardHolderName('');
          setCardValidDate('');
          setCardNumber('');
          setCardCVV('');
          setCardFlag('');
        }

        history.push(`/pay/success/${props.match.params.product}`,
          {
            paymentOption,
            prevURL: '/pay/payment-details',
            productName: productData?.name,
            billId: data?.bill?.id,
            productValue: data?.bill?.amount,
            couponCode: couponApplied,
            productId: productData?.id,
            discount,
            subscriptionType: productData?.subscription_type,
            type: productData?.type,
          });
      } catch (error: any) {
        setProcessingLoading(false);

        if (error.response.data?.message === errorMessageTypes.USER_NOT_FOUND) {
          toast.error(errorMessages.USER_NOT_FOUND);
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_PHONE) {
          toast.warn(errorMessages.USER_HAS_NOT_PHONE);
          openSavePhoneModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS_CPF);
          openSaveAddressCPFModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_CPF);
          openSaveCpfModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS);
          openSaveAddressModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_SUBSCRIPTION_VALID) {
          setShowHasSubscriptionValidMessage(true);
        } else if (error.response.data?.message === errorMessageTypes.COUPON_NOT_ALLOWED) {
          toast.error(errorMessages.COUPON_NOT_ALLOWED);
        } else if (error.response.data?.length > 0) {
          error.response.data.forEach((err: IError) => {
            if (err.id === 'invalid_parameter' && err.parameter === 'card_number') {
              toast.error(errorMessages.INVALID_CARD_NUMBER);
            } else if (err.id === 'invalid_parameter' && err.parameter === 'card_expiration') {
              toast.error(errorMessages.INVALID_CARD_EXPIRATION);
            } else if (err.id === 'invalid_parameter' && err.parameter === 'card_cvv') {
              toast.error(errorMessages.INVALID_CARD_CVV);
            } else if (err.id === 'invalid_parameter' && err.parameter === 'phones') {
              toast.error(errorMessages.INVALID_PHONE_NUMBER);
              openSavePhoneModal(true);
            } else if (err.id === 'invalid_parameter' && err.parameter === 'registry_code') {
              toast.error(errorMessages.INVALID_CPF);
              openSaveCpfModal();
            } else {
              toast.error(errorMessages.PROCESS_PAYMENT_FAILURE);
            }
          });
        } else if (error.response.data?.message) {
          toast.error(error.response.data?.message);
        } else {
          toast.error(errorMessages.PROCESS_PAYMENT_FAILURE);
        }
      }
    } else if (paymentOption === 2) {
      try {
        const payload: any = {
          payment_token: paymentToken,
          product: {
            id: productData?.external_id,
            couponCode: '',
          },
          self,
        };

        if (couponApplied) payload.product.couponCode = couponApplied;

        setProcessingLoaderText('Processando pagamento...');
        setProcessingLoading(true);

        if (valentine) {
          payload.nameValentine = name;
          payload.emailValentine = email;
        }

        const { data } = await api.post(`payments/vindi/with_bank_slip?cid=${cid}`, payload);

        history.push(`/pay/success/${props.match.params.product}`,
          {
            paymentOption,
            prevURL: '/pay/payment-details',
            productName: productData?.name,
            billLink: data?.bill?.url,
            billId: data?.bill?.id,
            productValue: data?.bill?.amount,
            couponCode: couponApplied,
            productId: productData?.id,
            discount,
            subscriptionType: productData?.subscription_type,
            type: productData?.type,
          });
      } catch (error: any) {
        setProcessingLoading(false);

        if (error.response.data?.message === errorMessageTypes.USER_NOT_FOUND) {
          toast.error(errorMessages.USER_NOT_FOUND);
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_PHONE) {
          toast.warn(errorMessages.USER_HAS_NOT_PHONE);
          openSavePhoneModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS_CPF);
          openSaveAddressCPFModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_CPF);
          openSaveCpfModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS);
          openSaveAddressModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_SUBSCRIPTION_VALID) {
          setShowHasSubscriptionValidMessage(true);
        } else if (error.response.data?.message === errorMessageTypes.COUPON_NOT_ALLOWED) {
          toast.error(errorMessages.COUPON_NOT_ALLOWED);
        } else {
          toast.error(errorMessages.PROCESS_PAYMENT_FAILURE);
        }
      }
    } else if (paymentOption === 3) {
      try {
        const payload: any = {
          payment_token: paymentToken,
          product: {
            id: productData?.external_id,
            couponCode: '',
          },
          self,
        };

        if (couponApplied) payload.product.couponCode = couponApplied;

        setProcessingLoaderText('Processando pagamento...');
        setProcessingLoading(true);

        if (valentine) {
          payload.nameValentine = name;
          payload.emailValentine = email;
          payload.phoneValentine = phone;
        }

        const { data } = await api.post(`payments/vindi/with_pix?cid=${cid}`, payload);

        history.push(`/pay/success/${props.match.params.product}`,
          {
            paymentOption,
            prevURL: '/pay/payment-details',
            productName: productData?.name,
            billLink: data?.bill?.url,
            billId: data?.bill?.id,
            productValue: data?.bill?.amount,
            couponCode: couponApplied,
            productId: productData?.id,
            discount,
            pixCode: data?.bill?.pix_code,
            subscriptionType: productData?.subscription_type,
            type: productData?.type,
          });
      } catch (error: any) {
        setProcessingLoading(false);

        if (error.response.data?.message === errorMessageTypes.USER_NOT_FOUND) {
          toast.error(errorMessages.USER_NOT_FOUND);
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_PHONE) {
          toast.warn(errorMessages.USER_HAS_NOT_PHONE);
          openSavePhoneModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS_CPF);
          openSaveAddressCPFModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_CPF) {
          toast.warn(errorMessages.USER_HAS_NOT_CPF);
          openSaveCpfModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_NOT_ADDRESS) {
          toast.warn(errorMessages.USER_HAS_NOT_ADDRESS);
          openSaveAddressModal();
        } else if (error.response.data?.message === errorMessageTypes.USER_HAS_SUBSCRIPTION_VALID) {
          setShowHasSubscriptionValidMessage(true);
        } else if (error.response.data?.message === errorMessageTypes.COUPON_NOT_ALLOWED) {
          toast.error(errorMessages.COUPON_NOT_ALLOWED);
        } else {
          toast.error(errorMessages.PROCESS_PAYMENT_FAILURE);
        }
      }
    }
  };

  function formatPaymentProfileCardFlagToIcon(card_flag: string) {
    switch (card_flag) {
      case 'visa':
        return visa_logo;
      case 'mastercard':
        return mastercard_logo;
      case 'elo':
        return elo_logo;
      case 'hipercard':
        return hipercard_logo;
      case 'american_express':
        return american_express_logo;
      case 'diners_club':
        return dinners_club_logo;
      case 'jcb':
        return jcb_logo;

      default:
        return undefined;
    }
  }

  return (
    <>
      {loading && <Loader />}

      <SavePhoneModal showModal={showSavePhoneModal} setShowModal={setShowSavePhoneModal} finishPayment={finishPayment} update={updatePhone} proceedPay={proceedPay} />

      <SaveCpfModal showModal={showSaveCpfModal} setShowModal={setShowSaveCpfModal} finishPayment={finishPayment} />

      <SaveAddressModal showModal={showSaveAddressModal} setShowModal={setShowSaveAddressModal} finishPayment={finishPayment} onlySaveAddress={onlySaveAddress} loadUser={loadUser} />

      <SaveAddressCPFModal showModal={showSaveCPFAddressModal} setShowModal={setShowSaveCPFAddressModal} finishPayment={finishPayment} onlySaveAddress={onlySaveAddress} loadUser={loadUser} />

      {!productData || !creditCardPaymentMethod || processingLoading
        ? <ProcessingLoader title={processingLoaderText} />
        : (
          <>
            <SignInModal showModal={showSignInModal} setShowModal={setShowSignInModal} />
            <SignUpModal showModal={showSignUpModal} setShowModal={setShowSignUpModal} setPaymentProfile={setPaymentProfile} productName={productData.name} gratuity={gratuity} />

            <SignUpSelfModal showModal={showSignSelfModal} setShowModal={setShowSignSelfModal} setPaymentProfile={setPaymentProfile} productName={productData.name} />

            <Header />

            <Steps>
              {!token ? <p>Passo 1 de 2: Identifique-se</p> : <p>Passo 2 de 2: Como prefere pagar?</p>}
            </Steps>

            <ErrorMsg>
              {
                showHasSubscriptionValidMessage
                && (
                  <>
                    <HasSubscriptionValidMessageContainer>
                      <HasSubscriptionValidMessage>
                        Encontramos uma assinatura vigente para esta conta. Entre em contato com nosso time:&nbsp;
                        <HasSubscriptionValidMessageMailLink href="mailto:assinatura@corridaperfeita.com">
                          assinatura@corridaperfeita.com
                        </HasSubscriptionValidMessageMailLink>
                      </HasSubscriptionValidMessage>
                    </HasSubscriptionValidMessageContainer>
                  </>
                )
              }
            </ErrorMsg>

            <Container>
              <strong>
                <ProductName>{productData.name}</ProductName>
              </strong>

              <ValueDetails>

                <strong>
                  <p>{convertRecurrenceToPaymentPeriodInstallments(productData.recurrence)}</p>
                </strong>

                {showCoupon && couponApplied
                  && (
                    <TotalOldValue>
                      <p>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(mainValue)}
                        &nbsp;à vista
                      </p>
                    </TotalOldValue>
                  )}
                {possibleInstallments.length === 0
                  && (
                    <p>
                      {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(mainValue)}
                      &nbsp;à vista
                    </p>
                  )}

                {
                  accessionValue > 0
                  && (
                    <>
                      <br />

                      <strong>
                        <p>Adesão</p>
                      </strong>

                      <p>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(accessionValue)}
                        &nbsp;à vista
                      </p>
                    </>
                  )
                }

                {
                  possibleInstallments.length > 1
                  && (!token || userCountry === 'BR')
                  && (
                    <>
                      {/* <p>ou</p> */}

                      {showCoupon && couponApplied
                        && (
                          <TotalOldValue>
                            {/* em até&nbsp; */}
                            <p>
                              <strong>
                                {maxInstallments}
                                x de&nbsp;
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productData.price / parseFloat(maxInstallments))}
                              </strong>
                            </p>
                          </TotalOldValue>
                        )}
                      <p>
                        {/* em até&nbsp; */}
                        <strong>
                          {maxInstallments}
                          x de&nbsp;
                          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productValue / parseFloat(maxInstallments))}
                        </strong>
                      </p>
                    </>
                  )
                }
              </ValueDetails>

              {showCoupon && couponApplied
                && (
                  <Discount>
                    <p>Cupom aplicado</p>
                    <strong>
                      <p>
                        {couponApplied}
                        {' '}
                        <DiscountButtonRemove type="button" onClick={removeCoupon}>X</DiscountButtonRemove>
                      </p>
                    </strong>
                  </Discount>
                )}

              {showCoupon && productData.allow_coupon === 1 && (
                <Discount>
                  <p>[-] Descontos</p>
                  <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(discount)}</p>
                </Discount>
              )}

              {!couponApplied && productData.allow_coupon === 1
                && (
                  <DiscountButton onClick={() => setHasCoupon(!hasCoupon)}>
                    <strong>Possui cupom de desconto?</strong>
                  </DiscountButton>
                )}

              <CouponFill style={{
                opacity: hasCoupon ? 1 : 0, overflow: hasCoupon ? 'visible' : 'hidden', height: hasCoupon ? '40px' : '0px',
              }}
              >
                <InputText type="text" placeholder="Insira o cupom" value={couponField} onChange={(e) => handleCoupon(e.target.value.toUpperCase().trim())} />
                <button type="button" onClick={() => applyCoupon(true)}>Aplicar</button>
              </CouponFill>

              {/* {showCoupon && couponApplied
                && (
                  <TotalOldValue>
                    <strong>
                      <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productData.price)}</p>
                    </strong>
                  </TotalOldValue>
                )} */}

              {/* <TotalValue>
                Total
                <strong>
                  <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productValue)}</p>
                </strong>
              </TotalValue> */}

              {productData.type === 'PLAN'
                && (
                  <RenewDate>
                    <p>Próxima renovação</p>
                    <p>{convertRecurrenceToPaymentNextBilling(productData.recurrence)}</p>
                  </RenewDate>
                )}
              {productData.type === 'PLAN'
                // && off
                && (
                  <RenewValue>
                    <p>Valor previsto na renovação</p>
                    <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productNextBillingValue)}</p>
                  </RenewValue>
                )}
              {/* {productData.type === 'PLAN'
                && !off
                && (
                  <RenewValue>
                    <p>Valor previsto na renovação</p>
                    <p>
                      {maxInstallments}
                      x de&nbsp;
                      {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productData.price / parseFloat(maxInstallments))}
                    </p>
                  </RenewValue>
                )} */}
            </Container>

            {
              token
              && (!hasAddress && !self)
              && (
                <AddressButtonContainer>
                  <SaveAddressButton onClick={openOnlySaveAddressModal}>
                    <strong>Cadastre seu endereço para continuar</strong>
                  </SaveAddressButton>
                </AddressButtonContainer>
              )
            }

            {
              token
              && (hasAddress || self)
              && productValue
              && valentine
              && (
                <PaymentMethodsContainer>
                  <OptionContainer>
                    <OptionTitle>
                      <strong>Informe os dados de seu parceiro(a) </strong>
                    </OptionTitle>
                  </OptionContainer>
                  <PartnerDetails>
                    <label htmlFor="name">Nome completo</label>
                    <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />

                    <label htmlFor="email">E-mail para login</label>
                    <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                    <label htmlFor="phone">Telefone celular</label>
                    <PhoneInput
                      country="br"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      countryCodeEditable={false}
                      inputProps={{
                        name: 'phone',
                        id: 'phone',
                        required: true,
                        autoFocus: true,
                      }}
                      containerStyle={{
                        width: '100%',
                        height: '47px',
                        border: 'none',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                      buttonStyle={{
                        border: 'none',
                        backgroundColor: theme.colors.cardB3,
                      }}
                      inputStyle={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        backgroundColor: theme.colors.cardB3,
                        color: '#fff',
                      }}
                    />
                  </PartnerDetails>
                </PaymentMethodsContainer>
              )
            }
            {
              token
              && (hasAddress || self)
              && productValue
              && (
                <PaymentMethodsContainer>
                  <OptionContainer>
                    <OptionTitle>
                      <strong>Como prefere pagar? </strong>
                    </OptionTitle>
                  </OptionContainer>
                  {creditCardPaymentMethod
                    && (
                      <>
                        <PaymentOption>
                          <input type="radio" id="credit_card" value="1" checked={paymentOption === 1} onChange={(e) => setPaymentOption(parseInt(e.target.value, 10))} />
                          &nbsp;
                          <label htmlFor="credit_card">
                            <strong>{creditCardPaymentMethod.public_name}</strong>
                          </label>
                          <p>
                            Em até&nbsp;
                            <strong>
                              {maxInstallments}
                              x de&nbsp;
                              <DiscountUniclass itau={isItauUniclass}>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productValue / parseFloat(maxInstallments))}
                              </DiscountUniclass>
                            </strong>
                          </p>
                        </PaymentOption>
                        {isItauUniclass && showDiscount
                          && (
                            <DiscountTitle>Desconto Itaú Uniclass aplicado</DiscountTitle>
                          )}
                        {paymentOption === 1
                          && (
                            <PaymentOptionDetails>
                              {
                                paymentProfile
                                  ? (
                                    <>
                                      <AlreadyCreditCardSavedTitle>Cartão cadastrado</AlreadyCreditCardSavedTitle>
                                      <AlreadyCreditCardSavedSubtitle>Número</AlreadyCreditCardSavedSubtitle>
                                      <AlreadyCreditCardSavedNumberContainer hasInstallments={possibleInstallments.length > 1}>
                                        <AlreadyCreditCardSavedNumber>
                                          <img
                                            src={formatPaymentProfileCardFlagToIcon(paymentProfile.payment_company.code)}
                                            alt={paymentProfile.payment_company.name}
                                            height="20px"
                                          />
                                          &nbsp;****&nbsp;****&nbsp;****&nbsp;
                                          {paymentProfile.card_number_last_four}
                                        </AlreadyCreditCardSavedNumber>
                                        <AlreadyCreditCardSavedSwitchButton onClick={() => setPaymentProfile(null)}>Trocar cartão</AlreadyCreditCardSavedSwitchButton>
                                      </AlreadyCreditCardSavedNumberContainer>
                                    </>
                                  )
                                  : (
                                    <>
                                      <ContainerFlags>
                                        <CardNumber>
                                          <label htmlFor="card_number">Número do cartão</label>
                                          <CardNumberInput
                                            defaultValue={cardNumber}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => getIssuer(e.target.value)}
                                          />
                                        </CardNumber>
                                        <CardFlag>
                                          <img
                                            src={formatPaymentProfileCardFlagToIcon(cardFlag)}
                                            alt={cardFlag}
                                            style={{ height: '40px', marginTop: '40%', marginLeft: '10%' }}
                                          />
                                        </CardFlag>

                                      </ContainerFlags>

                                      <label htmlFor="card_holder_name">Nome impresso no cartão</label>
                                      <InputText value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} type="text" id="card_holder_name" placeholder="Digite o nome impresso no cartão" />

                                      <CardSecurityCodeContainer>
                                        <CardValidDate>
                                          <label htmlFor="card_valid_date">Validade (MM/AAAA)</label>
                                          <CardValidDateInput
                                            defaultValue={cardValidDate}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCardValidDate(e.target.value)}
                                          />
                                        </CardValidDate>

                                        <CardSecurityCode>
                                          <label htmlFor="card_cvv">Cód. Segurança</label>
                                          <CardCVVInput
                                            defaultValue={cardCVV}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCardCVV(e.target.value)}
                                          />
                                        </CardSecurityCode>
                                      </CardSecurityCodeContainer>
                                    </>
                                  )
                              }

                              {
                                possibleInstallments.length > 1
                                && userCountry === 'BR'
                                && (
                                  <>
                                    <label htmlFor="installments">Parcelamento</label>
                                    <InstallmentsSelect id="installments" value={installments} onChange={(e) => setInstallments(e.target.value)}>
                                      {possibleInstallments.map((option) => (
                                        <InstallmentsSelectOption key={option} value={option}>
                                          {option}
                                          x de
                                          {' '}
                                          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productValue / parseFloat(option))}
                                        </InstallmentsSelectOption>
                                      ))}
                                    </InstallmentsSelect>
                                  </>
                                )
                              }
                            </PaymentOptionDetails>
                          )}
                      </>
                    )}
                  {pixPaymentMethod
                    && productData.allow_pix === 1
                    && (
                      <>
                        <PaymentOption>
                          <input type="radio" id="pix" value="3" checked={paymentOption === 3} onChange={(e) => setPaymentOption(parseInt(e.target.value, 10))} />
                          &nbsp;
                          <label htmlFor="pix">
                            <strong>
                              PIX
                              {possibleInstallments.length > 1 && ' (somente à vista)'}
                            </strong>
                            {/* {productData.subscription_type === 'ESSENTIAL'
                              && (
                                <>
                                  <br />
                                  <>
                                    Você ganhou
                                    (4%) de desconto no PIX
                                  </>
                                  <br />
                                  <strong>
                                    Pague apenas
                                    {' '}
                                    {
                                      new Intl.NumberFormat(
                                        'pt-BR',
                                        { style: 'currency', currency: 'BRL', currencyDisplay: 'code' },
                                      ).format(productValue > ((mainValue * 0.04) + (accessionValue * 0.04)) ? productValue - ((mainValue * 0.04) + (accessionValue * 0.04)) : 0)
                                        .replace('BRL', '')
                                        .trim()
                                    }
                                  </strong>
                                </>
                              )} */}
                          </label>
                        </PaymentOption>
                        {paymentOption === 3
                          && (
                            <PaymentOptionDetails>
                              <p>
                                *Opção disponível somente para brasileiros.
                              </p>
                              <p>
                                Você receberá uma fatura de pagamento com o código Pix.
                              </p>

                            </PaymentOptionDetails>
                          )}
                      </>
                    )}

                  {bankSlipPaymentMethod
                    && productData.allow_bank_slip === 1
                    && (
                      <>
                        <PaymentOption>
                          <input type="radio" id="bank_slip" value="2" checked={paymentOption === 2} onChange={(e) => setPaymentOption(parseInt(e.target.value, 10))} />
                          &nbsp;
                          <label htmlFor="bank_slip">
                            <strong>
                              Boleto bancário
                              {' '}
                              {possibleInstallments.length > 1 && '(somente à vista)'}
                            </strong>
                          </label>
                        </PaymentOption>
                        {paymentOption === 2
                          && (
                            <>
                              <GenericOptionDetails>
                                <p>
                                  *Opção disponível somente para brasileiros.
                                </p>
                              </GenericOptionDetails>
                              <GenericOptionDetails>
                                <p>
                                  Você receberá uma fatura de pagamento com o Boleto.
                                </p>
                              </GenericOptionDetails>
                            </>
                          )}
                      </>
                    )}
                </PaymentMethodsContainer>
              )
            }

            <AuthButtons>
              {!token ? (
                <>
                  <SignUpButton onClick={openSignUpModal}>
                    <strong>Cadastre-se para continuar</strong>
                  </SignUpButton>
                  <LoginButton onClick={openSignInModal}>
                    <strong>Já tenho cadastro</strong>
                  </LoginButton>
                </>
              ) : (
                <>
                  {
                    (hasAddress || self)
                    && (
                      <PayNow
                        onClick={finishPayment}
                      >
                        {productData.type === 'plan' ? <strong>Assinar agora</strong> : <strong>CONFIRMAR PAGAMENTO</strong>}
                      </PayNow>
                    )
                  }
                </>
              )}
            </AuthButtons>

            {token
              && hasAddress
              && (
                <AuxTextFooter>
                  <p>
                    Ao assinar você concorda com os
                    {' '}
                    <strong>
                      <a href={process.env.REACT_APP_CP_TERMS_URL} target="_blank" rel="noopener noreferrer">termos de uso</a>
                    </strong>
                    {' '}
                    e a
                    {' '}

                    <strong><a href={process.env.REACT_APP_CP_POLICY_URL} target="_blank" rel="noopener noreferrer">política de privacidade</a></strong>
                    .
                  </p>
                </AuxTextFooter>
              )}

            <SSLStamp>
              <img src={sslStampImage} alt="compra-dados-seguros" />
            </SSLStamp>
          </>
        )}
    </>
  );
}

export default PaymentDetails;
